@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 900;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 200;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 100;
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
}